import React, { useState, useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  CheckOutlined,
  AddOutlined,
  FilePresent,
  ArrowDropDown,
  KeyboardArrowDownOutlined,
  FileUploadOutlined,
  DeleteOutline,
  ErrorOutlined,
  KeyboardArrowUpOutlined,
  CircleRounded,
  CheckBoxOutlined,
  MoreVert,
} from "@mui/icons-material";
import PdfPreviewModal from "./PdfPreviewModal";
import ShowEmission from "./ShowEmission";
import { unitTypes } from "components/data/units";
import { useDispatch } from "react-redux";
import { setRowsStateNew, selectRowsStateNew } from "state/emissionSlice";
import _ from "lodash";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { setLocation, setYear, setQuarter } from "state/emissionSlice";
import FileIconExcel from "./FileIcon";
import AssignedTaskRows from "./AssignedTasks";
import { Tooltip } from "react-tooltip";

function CollectEmission({
  scope,
  scopeInfo,
  data,
  assignedData,
  prevMonth,
  errorState,
  locError,
  monthlyEmissions,
  approvedRows,
  setTaskAssigned,
}) {
  const countryCode = useSelector((state) => state.emission?.countryCode);
  const quarter = useSelector((state) => state.emission?.quarter);
  const token = useSelector((state) => state.global.authToken);
  const location = useSelector((state) => state.emission.location);
  const dispatch = useDispatch();
  // Underline comes after selecting input
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectedActivity, setSelectedActivity] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [assigndatasubCategory, setAssigndatasubCategory] = useState();
  const [assigndataactivity, setAssigndataactivity] = useState();
  const [assigndatacategory, setAssigndatacategory] = useState();
  const [assigndataFactorid, setassigndataFactorid] = useState();
  const [assignIndex, setAssignIndex] = useState();
  const [loopen, setLoOpen] = useState(false);
  const [clintlist, setClintlist] = useState();
  const [usernameasssin, setUsernameassin] = useState();
  const [duedate, setDuedate] = useState();
  const [error, setError] = useState({});
  const isMounted = useRef(true);
  // my task data passing data//
  const newlocation = useLocation();
  const newdata = newlocation.state?.data;

  useEffect(() => {
    if (isMounted.current) {
      console.log(newdata, "testdata");
      isMounted.current = false;
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  const rowsState = useSelector((state) =>
    selectRowsStateNew(state, quarter, scope)
  );

  const currentUser = localStorage.getItem("email");

  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (newdata && Object.keys(newdata).length !== 0) {
      dispatch(setLocation(newdata.location));
      dispatch(setQuarter(newdata.month));
      dispatch(setYear(String(newdata.year)));
    }
  }, []);

  const handleAddRow = () => {
    const newRow = {
      category: "",
      subCategory: "",
      activities: [],
      value1: "",
      value2: "",
      unit: ["", ""],
      fileRaw: "",
      file: null,
      fileName: "",
      modifiedTime: "",
      activity: "",
      selectedActivity: {},
      unitType: "",
      assignTo: "",
      uploadedBy: currentUser,
      noActivities: false,
      fetchingActivities: false,
    };

    const updatedRows = [...rowsState, newRow];
    dispatch(setRowsStateNew({ quarter, scope, rows: updatedRows }));
    setRows(updatedRows);

    setSelectedCategory("");
    setSelectedSubCategory("");
    setSelectedActivity("");
  };

  const [showCheckmark, setShowCheckmark] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [currentStat, setCurrentStat] = useState({
    index: -1,
    field: "fuel",
  });
  const [loading, setLoading] = useState(false);
  let wildcard = false;

  const handleChange = async (event, index, field) => {
    setLoading(true);
    setCurrentStat({ ...currentStat, index: index });
    if (index >= 0 && index < rowsState.length) {
      let newRows = _.cloneDeep(rowsState);
      if (field === "category") {
        if (location) {
          setSelectedCategory(event.target.value);
          newRows[index].category = event.target.value;
          setSelectedSubCategory("");
          setSelectedActivity("");
          wildcard = false;
        } else locError(true);
      } else if (field === "subCategory") {
        setSelectedSubCategory(event.target.value);
        setSelectedActivity("");
      } else if (field === "activity") {
        const selectedUnitType =
          event.target.options[event.target.selectedIndex].dataset.unitType;
        newRows[index].activity = event.target.value;
        newRows[index].unitType = selectedUnitType || null;
        const selectedId =
          event.target.options[event.target.selectedIndex].dataset.id;
        const activitySelected = newRows[index].activities.find(
          (activity) => activity.id === selectedId
        );
        newRows[index].selectedActivity = activitySelected;
        setSelectedActivity(event.target.value);
      }
      if (field === "subCategory") {
        newRows[index].fetchingActivities = true;
        newRows[index].activities = [];
        dispatch(setRowsStateNew({ quarter, scope, rows: newRows }));
        const categorySelected = newRows[index].category;
        const newSubCategory = event.target.value;
        newRows[index].subCategory = newSubCategory;
        setCurrentStat({ ...currentStat, field: newSubCategory });

        try {
          const response = await fetchActivities(
            index,
            newSubCategory,
            1,
            false
          );
          const { activitiesData, pages, pagesCustom } = response;

          activitiesData.sort((a, b) => {
            if (a.access_type === "private" && b.access_type !== "private") {
              return -1;
            } else if (
              a.access_type !== "private" &&
              b.access_type === "private"
            ) {
              return 1;
            }
            return 0;
          });

          newRows[index].activities = activitiesData;
          if (activitiesData.length === 0) newRows[index].noActivities = true;
          else newRows[index].noActivities = false;
          newRows[index].fetchingActivities = false;
          newRows[index].activity = "";
          newRows[index].assignTo = "";
          newRows[index].unit = [];

          dispatch(setRowsStateNew({ quarter, scope, rows: newRows }));
          setRows(newRows);

          if (pages > 1) {
            let customFetched = false;
            for (let i = 2; i <= pages; i++) {
              if (pagesCustom > 1 && i <= pagesCustom) {
                customFetched = false;
              } else {
                customFetched = true;
              }
              const response = await fetchActivities(
                index,
                newSubCategory,
                i,
                customFetched
              );
              const { activitiesData } = response;

              const newCombinedActivities = [
                ...activitiesData,
                ...newRows[index].activities,
              ];

              newCombinedActivities.sort((a, b) => {
                if (
                  a.access_type === "private" &&
                  b.access_type !== "private"
                ) {
                  return -1;
                } else if (
                  a.access_type !== "private" &&
                  b.access_type === "private"
                ) {
                  return 1;
                }

                return a.name.localeCompare(b.name);
              });

              newRows[index].activities = newCombinedActivities;
              if (newCombinedActivities.length === 0) {
                newRows[index].noActivities = true;
                newRows[index].activities = [];
              } else newRows[index].noActivities = false;
              newRows[index].fetchingActivities = false;

              if (i % 3 === 0) {
                dispatch(setRowsStateNew({ quarter, scope, rows: newRows }));
                setRows(newRows);
              }
            }
          }
        } catch (error) {
          setLoading(false);
          console.error("Error fetching activities: ", error);
        }
      } else if (field === "assignTo") {
        let name;
        if (
          event.target &&
          event.target.options &&
          event.target.selectedIndex !== undefined
        ) {
          const selectedOption =
            event.target.options[event.target.selectedIndex];
          name = selectedOption.getAttribute("data-name");
        } else {
          name = "";
        }

        newRows[index].assignTo = name;
      } else if (field === "unit") {
        const selectedUnit = event.target.value;
        const allUnits = rows[index].unit;
        newRows[index].unit = [...allUnits, selectedUnit];
      } else if (field === "unit1") {
        const selectedUnit = event.target.value;
        newRows[index].unit[0] = selectedUnit;
      } else if (field === "unit2") {
        const selectedUnit = event.target.value;
        newRows[index].unit[1] = selectedUnit;
      } else if (field === "value1") {
        const selectedValue = event.target.value;
        if (/^\s*$|^$|^(-)?(0|[1-9]\d*)\.?\d{0,3}$/.test(selectedValue)) {
          newRows[index].value1 = selectedValue;
        } else if (selectedValue === null)
          newRows[index].value1 = selectedValue;
      } else if (field === "value2") {
        const selectedValue = event.target.value;
        if (/^\s*$|^$|^(-)?(0|[1-9]\d*)\.?\d{0,3}$/.test(selectedValue)) {
          newRows[index].value2 = selectedValue;
        }
      } else if (field === "file") {
        console.log("file upload triggered");

        const uploadedFile = event.target.files[0];
        if (uploadedFile && uploadedFile.size > 4 * 1024 * 1024) {
          alert("File size exceeds the maximum allowed size (4 MB).");
          return;
        }
        if (uploadedFile) {
          const reader = new FileReader();
          reader.readAsDataURL(uploadedFile);
          reader.onload = (event) => {
            newRows[index].file = event.target.result;
            newRows[index].fileName = uploadedFile.name;
            newRows[index].modifiedTime = new Date().toLocaleString();
            newRows[index].uploadedBy = localStorage.getItem("email");
            setIsUploaded(true);
            setTimeout(() => {
              setShowCheckmark(false);
            }, 2000);
            dispatch(setRowsStateNew({ quarter, scope, rows: newRows }));
            setRows(newRows);
          };
        }
      }
      if (field !== "file") {
        dispatch(setRowsStateNew({ quarter, scope, rows: newRows }));
      }
      setRows(newRows);
    } else {
      setLoading(false);
      console.error(`Invalid index: ${index}`);
    }
    setLoading(false);
  };

  const fetchedYear = useSelector((state) => state.emission?.year);
  let year =
    String(fetchedYear).length === 4
      ? fetchedYear
      : fetchedYear.substring(0, 4);

  // Custom
  // async function fetchActivities(index, category, page, customFetchExecuted) {
  //   const baseURL = "https://api.climatiq.io";
  //   const resultsPerPage = 500;
  //   const axiosConfig = {
  //     headers: {
  //       Authorization: `Bearer ${process.env.REACT_APP_CLIMATIQ_KEY}`,
  //       Accept: "application/json",
  //       "Content-type": "application/json",
  //     },
  //   };
  //   const region = countryCode || "*";
  //   let currentYear = year;
  //   let wildcardResultZero = false;

  //   let activitiesData = [];
  //   let totalResults = 0;
  //   let totalPrivateResults = 0;
  //   let totalPages;
  //   let totalPagesCustom = 0;
  //   let wildcardActivitiesData = [];
  //   let yearlyResponseData = [];
  //   let newActivitiesData = [];
  //   let customFetchData = [];
  //   let multipleSourceData = [];
  //   let finalActivitiesData = [];

  //   try {
  //     if (!wildcard) {
  //       const url = `${baseURL}/data/v1/search?results_per_page=${resultsPerPage}&year=${currentYear}&region=${region}*&category=${category}&page=${page}&data_version=^${process.env.REACT_APP_CLIMATIQ_DATAVERSION}`;

  //       const response = await axios.get(url, axiosConfig);
  //       activitiesData = response.data.results;
  //       totalResults = response.data.results.length;
  //       totalPages = response.data.last_page;
  //       totalPrivateResults = activitiesData.reduce((count, activity) => {
  //         if (activity.access_type === "private") {
  //           count += 1;
  //         }
  //         return count;
  //       }, 0);
  //     }

  //     const effectiveCount = totalResults - totalPrivateResults;
  //     if (effectiveCount <= 5) {
  //       wildcard = true;
  //     }
  //     // If less results were found and it's the first page, try a wildcard search
  //     if (wildcard) {
  //       const wildcardResponse = await axios.get(
  //         `${baseURL}/data/v1/search?results_per_page=${resultsPerPage}&year=${currentYear}&region=*&category=${category}&page=${page}&data_version=^${process.env.REACT_APP_CLIMATIQ_DATAVERSION}`,
  //         axiosConfig
  //       );
  //       wildcardActivitiesData = wildcardResponse.data.results;
  //       totalPages = wildcardResponse.data.last_page;

  //       if (totalPages === 0) wildcardResultZero = true;
  //     }

  //     if (wildcardResultZero) {
  //       for (let i = currentYear - 1; i >= 2019; i--) {
  //         const yearlyResponse = await axios.get(
  //           `${baseURL}/data/v1/search?results_per_page=${resultsPerPage}&year=${i}&region=${region}*&category=${category}&page=${page}&data_version=^${process.env.REACT_APP_CLIMATIQ_DATAVERSION}`,
  //           axiosConfig
  //         );
  //         const yearlyActivitiesData = yearlyResponse.data.results;
  //         totalPages = yearlyResponse.data.last_page;
  //         yearlyResponseData = [...yearlyResponseData, ...yearlyActivitiesData];
  //         if (yearlyActivitiesData.length !== 0) break;
  //       }
  //     }

  //     // Filter out private activities
  //     newActivitiesData = wildcardActivitiesData.filter(
  //       (activity) => activity.access_type !== "private"
  //     );

  //     const CombinedActivitiesData = [
  //       ...activitiesData,
  //       ...newActivitiesData,
  //       ...yearlyResponseData,
  //     ];

  //     const categoriesToAppend = [
  //       "Vehicles",
  //       "Clothing and Footwear",
  //       "DIY and Gardening Equipment",
  //       "Domestic Services",
  //       "Education",
  //       "Electrical Equipment",
  //       "Equipment Rental",
  //       "Food and Beverage Services",
  //       "Furnishings and Household",
  //       "General Retail",
  //       "Government Activities",
  //       "Health and Social Care",
  //       "Information and Communication Services",
  //       "Office Equipment",
  //       "Plastics and Rubber Products",
  //       "Professional Services and Activities",
  //       "Waste Management",
  //       "Water Treatment",
  //       "Electrical Equipment",
  //       "Furnishings and Household",
  //       "Office Equipment",
  //       "Restaurants and Accommodation",
  //       "Vehicles",
  //       "Recreation and Culture",
  //       "Accommodation",
  //       "Vehicle Maintenance and Services",
  //       "Road Travel",
  //       "Maintenance and Repair",
  //       "Electronics",
  //       "Paper Products",
  //       "Building Materials",
  //       // "Utilities",
  //     ];

  //     const categoryMappings = {
  //       Accommodation: [{ source: "BEIS", year: "2021" }],
  //       Vehicles: [{ source: "EXIOBASE", year: "2019" }],
  //       // "Road Travel":[{ source: "Ecoinvent", year: "2019" }],
  //       "Clothing and Footwear": [
  //         { source: "EXIOBASE", year: "2019" },
  //         { source: "EPA", year: "2018" },
  //       ],
  //       "DIY and Gardening Equipment": [{ source: "EPA", year: "2019" }],
  //       "Domestic Services": [{ source: "EXIOBASE", year: "2019" }],
  //       Education: [{ source: "EXIOBASE", year: "2019" }],
  //       "Electrical Equipment": [{ source: "EXIOBASE", year: "2019" }],
  //       "Equipment Rental": [{ source: "EXIOBASE", year: "2019" }],
  //       "Food and Beverage Services": [
  //         { source: "EPA", year: "2019" },
  //         { source: "BEIS", year: "2019" },
  //       ],
  //       "Furnishings and Household": [
  //         { source: "EXIOBASE", year: "2019" },
  //         { source: "BEIS", year: "2020" },
  //       ],
  //       "General Retail": [{ source: "EXIOBASE", year: "2019" }],
  //       "Government Activities": [{ source: "EXIOBASE", year: "2019" }],
  //       "Health and Social Care": [{ source: "EXIOBASE", year: "2019" }],
  //       "Information and Communication Services": [
  //         { source: "EXIOBASE", year: "2019" },
  //         { source: "BEIS", year: "2020" },
  //       ],
  //       "Post and Telecommunication": [{ source: "EXIPOBASE", year: "2019" }],
  //       "Office Equipment": [
  //         { source: "EXIOBASE", year: "2019" },
  //         { source: "EPA", year: "2018" },
  //         { source: "EPA", year: "2019" },
  //       ],
  //       "Paper Products": [
  //         { source: "EPA", year: "2018" },
  //         { source: "EXIOBASE", year: "2019" },
  //         { source: "BEIS", year: "2020" },
  //       ],
  //       // "Utilities": [{ source: "EXIOBASE", year: "2019" }],
  //       "Plastics and Rubber Products": [{ source: "EXIOBASE", year: "2019" }],
  //       "Professional Services and Activities": [
  //         { source: "EXIOBASE", year: "2019" },
  //         { source: "EXIOBASE", year: "2020" },
  //         { source: "EPA", year: "2019" },
  //         { source: "BEIS", year: "2020" },
  //       ],
  //       "Waste Management": [{ source: "EXIOBASE", year: "2019" }],
  //       // "Water Supply": [{ source: 'Ecoinvent', year: '2015' }],
  //       "Water Treatment": [{ source: "EXIOBASE", year: "2019" }],
  //       "Restaurants and Accommodation": [{ source: "EXIOBASE", year: "2019" }],
  //       "Recreation and Culture": [
  //         { source: "EPA", year: "2018" },
  //         { source: "EPA", year: "2019" },
  //       ],
  //       "Consumer Goods and Services": [{ source: "EPA", year: "2018" }],
  //       "Vehicle Maintenance and Services": [
  //         { source: "EPA", year: "2019" },
  //         {
  //           source: "EXIOBASE",
  //           year: "2019",
  //           region: "AU",
  //           category: "Vehicles",
  //         },
  //         { source: "BEIS", year: "2020", region: "GB", category: "Vehicles" },
  //       ],
  //       "Road Travel": [{ source: "EPA", year: "2019" }],
  //       "Maintenance and Repair": [{ source: "EPA", year: "2018" }],
  //       Electronics: [{ source: "BEIS", year: "2020" }],
  //       "Building Materials": [
  //         { source: "EXIOBASE", year: "2019" },
  //         { source: "Circular Ecology", year: "2019" },
  //       ],
  //     };

  //     if (
  //       categoriesToAppend.includes(category) &&
  //       categoryMappings[category] &&
  //       !customFetchExecuted
  //     ) {
  //       for (const entry of categoryMappings[category]) {
  //         const source = entry.source;
  //         const year = entry.year;

  //         const url = `${baseURL}/data/v1/search?results_per_page=${resultsPerPage}&source=${source}&year=${year}&region=*&category=${category}&page=${page}&data_version=^${process.env.REACT_APP_CLIMATIQ_DATAVERSION}`;
  //         const response = await axios.get(url, axiosConfig);
  //         customFetchData = customFetchData.concat(response.data.results); // Accumulate custom fetched data
  //         // multipleSourceData = CombinedActivitiesData.concat(response.data.results);
  //         finalActivitiesData = [
  //           ...customFetchData,
  //           ...activitiesData,
  //           ...newActivitiesData,
  //           ...yearlyResponseData,
  //         ];
  //         totalPagesCustom = response.data.last_page;
  //       }
  //     }
  //     if (!customFetchExecuted) {
  //       return {
  //         activitiesData: [...CombinedActivitiesData, ...customFetchData],
  //         pages: totalPages,
  //         pagesCustom: totalPagesCustom,
  //       };
  //     } else {
  //       return {
  //         activitiesData: CombinedActivitiesData,
  //         pages: totalPages,
  //       };
  //     }
  //   } catch (error) {
  //     // ! Throws Error if couldn't fetch data
  //     console.error("Error fetching data from different regions: ", error);
  //     throw error;
  //   }
  // }

  async function fetchActivities(index, category, page, customFetchExecuted) {
    const baseURL = "https://api.climatiq.io";
    const resultsPerPage = 500;
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_CLIMATIQ_KEY}`,
        Accept: "application/json",
        "Content-type": "application/json",
      },
    };
    const region = countryCode || "*";
    let currentYear = year;
    let wildcardResultZero = false;

    let activitiesData = [];
    let totalResults = 0;
    let totalPrivateResults = 0;
    let totalPages;
    let totalPagesCustom = 0;
    let wildcardActivitiesData = [];
    let yearlyResponseData = [];
    let newActivitiesData = [];
    let customFetchData = [];
    let multipleSourceData = [];
    let finalActivitiesData = [];

    try {
      if (!wildcard) {
        // Fetch data for the current year
        const currentYearUrl = `${baseURL}/data/v1/search?results_per_page=${resultsPerPage}&year=${currentYear}&region=${region}*&category=${category}&page=${page}&data_version=^${process.env.REACT_APP_CLIMATIQ_DATAVERSION}`;
        const currentYearResponse = await axios.get(currentYearUrl, axiosConfig);
        activitiesData = currentYearResponse.data.results;

        // Fetch data for the previous year
        const previousYear = currentYear - 1;
        const previousYearUrl = `${baseURL}/data/v1/search?results_per_page=${resultsPerPage}&year=${previousYear}&region=${region}*&category=${category}&page=${page}&data_version=^${process.env.REACT_APP_CLIMATIQ_DATAVERSION}`;
        const previousYearResponse = await axios.get(previousYearUrl, axiosConfig);
        
        // Concatenate the results
        activitiesData = [...activitiesData, ...previousYearResponse.data.results];

        totalResults = activitiesData.length;
        totalPages = Math.max(currentYearResponse.data.last_page, previousYearResponse.data.last_page);
        totalPrivateResults = activitiesData.reduce((count, activity) => {
          if (activity.access_type === "private") {
            count += 1;
          }
          return count;
        }, 0);
      }

      const effectiveCount = totalResults - totalPrivateResults;
      if (effectiveCount <= 5) {
        wildcard = true;
      }
      // If less results were found and it's the first page, try a wildcard search
      if (wildcard) {
        const wildcardResponse = await axios.get(
          `${baseURL}/data/v1/search?results_per_page=${resultsPerPage}&year=${currentYear}&region=*&category=${category}&page=${page}&data_version=^${process.env.REACT_APP_CLIMATIQ_DATAVERSION}`,
          axiosConfig
        );
        wildcardActivitiesData = wildcardResponse.data.results;
        totalPages = wildcardResponse.data.last_page;

        if (totalPages === 0) wildcardResultZero = true;
      }

      if (wildcardResultZero) {
        for (let i = currentYear - 2; i >= 2019; i--) {
          const yearlyResponse = await axios.get(
            `${baseURL}/data/v1/search?results_per_page=${resultsPerPage}&year=${i}&region=${region}*&category=${category}&page=${page}&data_version=^${process.env.REACT_APP_CLIMATIQ_DATAVERSION}`,
            axiosConfig
          );
          const yearlyActivitiesData = yearlyResponse.data.results;
          totalPages = yearlyResponse.data.last_page;
          yearlyResponseData = [...yearlyResponseData, ...yearlyActivitiesData];
          if (yearlyActivitiesData.length !== 0) break;
        }
      }

      // Filter out private activities
      newActivitiesData = wildcardActivitiesData.filter(
        (activity) => activity.access_type !== "private"
      );

      const CombinedActivitiesData = [
        ...activitiesData,
        ...newActivitiesData,
        ...yearlyResponseData,
      ];

      const categoriesToAppend = [
        "Vehicles",
        "Clothing and Footwear",
        "DIY and Gardening Equipment",
        "Domestic Services",
        "Education",
        "Electrical Equipment",
        "Equipment Rental",
        "Food and Beverage Services",
        "Furnishings and Household",
        "General Retail",
        "Government Activities",
        "Health and Social Care",
        "Information and Communication Services",
        "Office Equipment",
        "Plastics and Rubber Products",
        "Professional Services and Activities",
        "Waste Management",
        "Water Treatment",
        "Electrical Equipment",
        "Furnishings and Household",
        "Office Equipment",
        "Restaurants and Accommodation",
        "Vehicles",
        "Recreation and Culture",
        "Accommodation",
        "Vehicle Maintenance and Services",
        "Road Travel",
        "Maintenance and Repair",
        "Electronics",
        "Paper Products",
        "Building Materials",
        // "Utilities",
      ];

      const categoryMappings = {
        Accommodation: [{ source: "BEIS", year: "2021" }],
        Vehicles: [{ source: "EXIOBASE", year: "2019" }],
        // "Road Travel":[{ source: "Ecoinvent", year: "2019" }],
        "Clothing and Footwear": [
          { source: "EXIOBASE", year: "2019" },
          { source: "EPA", year: "2018" },
        ],
        "DIY and Gardening Equipment": [{ source: "EPA", year: "2019" }],
        "Domestic Services": [{ source: "EXIOBASE", year: "2019" }],
        Education: [{ source: "EXIOBASE", year: "2019" }],
        "Electrical Equipment": [{ source: "EXIOBASE", year: "2019" }],
        "Equipment Rental": [{ source: "EXIOBASE", year: "2019" }],
        "Food and Beverage Services": [
          { source: "EPA", year: "2019" },
          { source: "BEIS", year: "2019" },
        ],
        "Furnishings and Household": [
          { source: "EXIOBASE", year: "2019" },
          { source: "BEIS", year: "2020" },
        ],
        "General Retail": [{ source: "EXIOBASE", year: "2019" }],
        "Government Activities": [{ source: "EXIOBASE", year: "2019" }],
        "Health and Social Care": [{ source: "EXIOBASE", year: "2019" }],
        "Information and Communication Services": [
          { source: "EXIOBASE", year: "2019" },
          { source: "BEIS", year: "2020" },
        ],
        "Post and Telecommunication": [{ source: "EXIPOBASE", year: "2019" }],
        "Office Equipment": [
          { source: "EXIOBASE", year: "2019" },
          { source: "EPA", year: "2018" },
          { source: "EPA", year: "2019" },
        ],
        "Paper Products": [
          { source: "EPA", year: "2018" },
          { source: "EXIOBASE", year: "2019" },
          { source: "BEIS", year: "2020" },
        ],
        // "Utilities": [{ source: "EXIOBASE", year: "2019" }],
        "Plastics and Rubber Products": [{ source: "EXIOBASE", year: "2019" }],
        "Professional Services and Activities": [
          { source: "EXIOBASE", year: "2019" },
          { source: "EXIOBASE", year: "2020" },
          { source: "EPA", year: "2019" },
          { source: "BEIS", year: "2020" },
        ],
        "Waste Management": [{ source: "EXIOBASE", year: "2019" }],
        // "Water Supply": [{ source: 'Ecoinvent', year: '2015' }],
        "Water Treatment": [{ source: "EXIOBASE", year: "2019" }],
        "Restaurants and Accommodation": [{ source: "EXIOBASE", year: "2019" }],
        "Recreation and Culture": [
          { source: "EPA", year: "2018" },
          { source: "EPA", year: "2019" },
        ],
        "Consumer Goods and Services": [{ source: "EPA", year: "2018" }],
        "Vehicle Maintenance and Services": [
          { source: "EPA", year: "2019" },
          {
            source: "EXIOBASE",
            year: "2019",
            region: "AU",
            category: "Vehicles",
          },
          { source: "BEIS", year: "2020", region: "GB", category: "Vehicles" },
        ],
        "Road Travel": [{ source: "EPA", year: "2019" }],
        "Maintenance and Repair": [{ source: "EPA", year: "2018" }],
        Electronics: [{ source: "BEIS", year: "2020" }],
        "Building Materials": [
          { source: "EXIOBASE", year: "2019" },
          { source: "Circular Ecology", year: "2019" },
        ],
      };

      if (
        categoriesToAppend.includes(category) &&
        categoryMappings[category] &&
        !customFetchExecuted
      ) {
        for (const entry of categoryMappings[category]) {
          const source = entry.source;
          const year = entry.year;

          const url = `${baseURL}/data/v1/search?results_per_page=${resultsPerPage}&source=${source}&year=${year}&region=*&category=${category}&page=${page}&data_version=^${process.env.REACT_APP_CLIMATIQ_DATAVERSION}`;
          const response = await axios.get(url, axiosConfig);
          customFetchData = customFetchData.concat(response.data.results); // Accumulate custom fetched data
          // multipleSourceData = CombinedActivitiesData.concat(response.data.results);
          finalActivitiesData = [
            ...customFetchData,
            ...activitiesData,
            ...newActivitiesData,
            ...yearlyResponseData,
          ];
          totalPagesCustom = response.data.last_page;
        }
      }
      if (!customFetchExecuted) {
        return {
          activitiesData: [...CombinedActivitiesData, ...customFetchData],
          pages: totalPages,
          pagesCustom: totalPagesCustom,
        };
      } else {
        return {
          activitiesData: CombinedActivitiesData,
          pages: totalPages,
        };
      }
    } catch (error) {
      // ! Throws Error if couldn't fetch data
      console.error("Error fetching data from different regions: ", error);
      throw error;
    }
  }

  // Modal for File preview
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [previewRowIndex, setPreviewRowIndex] = useState(null);
  const [iframePreview, setIframePreview] = useState(false);

  const handleGreenIconClick = (index, source) => {
    console.log('Green icon clicked, row source is:', source);
    
    if (source === "Approved") {
      setIframePreview(true);
    }
    else if(source === "not defined"){
      toast.error("TPreview not available", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      setIframePreview(false);
      setPreviewRowIndex(index);
      setShowPreviewModal(true);
    }
  };

  const closeModal = () => {
    setShowPreviewModal(false);
  };

  useEffect(() => {
    if (isUploaded) {
      setShowCheckmark(true);
      const timer = setTimeout(() => {
        setShowCheckmark(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [isUploaded]);

  const [units, setUnits] = useState(unitTypes);

  // Delete row on frontend

  const handleDeleteRow = (index) => {
    const updatedRows = [...rowsState];
    updatedRows.splice(index, 1);
    dispatch(setRowsStateNew({ quarter, scope, rows: updatedRows }));
    setRows(updatedRows);
  };

  const collectErrorFieldNames = () => {
    const errorFieldNames = [];

    for (const fieldName in errorState) {
      if (errorState[fieldName]) {
        errorFieldNames.push(fieldName);
      }
    }

    return errorFieldNames;
  };

  // Collect field names with errors
  const errorFieldNames = collectErrorFieldNames();

  const uniqueValueBasedOnActivities = useMemo(() => {
    return rowsState
      .map((row) => row.activities?.map((activity) => activity.id).join(","))
      .join("|");
  }, [rowsState]);

  const fetchClintlist = async () => {
    const stringWithQuotes = localStorage.getItem("authTokens");
    const stringWithoutQuotes = stringWithQuotes.replace(/"/g, "");
    const options = {
      headers: {
        Authorization: `Token ${stringWithoutQuotes}`,
      },
    };
    LoaderOpen();
    const response = await axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/sustainapp/user_client/`,
        options
      )
      .then((response) => {
        // Handle the response here.
        console.log(response.data, "clintdata");
        setClintlist(response.data);
        LoaderClose();
      })
      .catch((error) => {
        setClintlist();
        LoaderClose();
      });
  };
  const LoaderOpen = () => {
    setLoOpen(true);
  };
  const LoaderClose = () => {
    setLoOpen(false);
  };
  const openModal = (row, index) => {
    console.log("row in open modal", row);
    setIsModalOpen(true);
    setAssigndatasubCategory(row.subCategory);
    setAssigndataactivity(row.activity.trim());
    setAssigndatacategory(row.category);
    setassigndataFactorid(row.selectedActivity.id);
    setAssignIndex(index);
    fetchClintlist();
  };
  const closeModalnew = (e) => {
    setIsModalOpen(false);
    setError({});
    setUsernameassin();
    handleChange(e, assignIndex, "assignTo");
  };
  const handleUsername = (e) => {
    setUsernameassin(e.target.value);
    handleChange(e, assignIndex, "assignTo");
  };
  const hanleDuedate = (e) => {
    setDuedate(e.target.value);
  };
  const getTodayDate = () => {
    const today = new Date();
    let month = "" + (today.getMonth() + 1);
    let day = "" + today.getDate();
    const year = today.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };
  const validateForm = () => {
    let newErrors = {};
    if (!assigndatasubCategory) {
      newErrors.assigndatasubCategory = "This field is required.";
    }
    if (!usernameasssin) {
      newErrors.usernameasssin = "This field is required.";
    }
    if (!duedate) {
      newErrors.duedate = "This field is required.";
    }
    return newErrors;
  };
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission

    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      setError({}); // Clear any existing errors
      await handleupdateform(); // Proceed with the form submission
    } else {
      setError(formErrors); // Update the state with the validation errors
    }
  };

  const SelectedRegion = useSelector((state) => state.emission.countryCode);

  const handleupdateform = async () => {
    const stringWithQuotes = localStorage.getItem("authTokens");
    const stringWithoutQuotes = stringWithQuotes.replace(/"/g, "");
    const options = {
      headers: {
        Authorization: `Token ${stringWithoutQuotes}`,
      },
    };
    LoaderOpen();

    const sandData = {
      location: location,
      year: fetchedYear,
      subcategory: assigndatasubCategory,
      activity: assigndataactivity,
      task_name: assigndataactivity
        ? `${location}-${quarter}-${assigndataactivity}`
        : `${location}-${quarter}-${assigndatasubCategory}`,
      scope: scope,
      month: quarter,
      roles: 1,
      deadline: duedate,
      assigned_by: parseInt(localStorage.getItem("user_id")),
      assigned_to: parseInt(usernameasssin),
      user_client: 1,
      category: assigndatacategory,
      factor_id: assigndataFactorid,
      region: SelectedRegion,
    };
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/organization_task_dashboard/`,
        sandData,
        options
      )
      .then((response) => {
        if (response.status == "201") {
          toast.success("Task assigned successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          LoaderClose();
          closeModalnew();
          setError({});
          setUsernameassin();
          setDuedate();
          //remove that row
          const newRows = rowsState.filter(
            (_, rowIndex) => rowIndex !== assignIndex
          );
          dispatch(setRowsStateNew({ quarter, scope, rows: newRows }));
          setRows(newRows);
          setTaskAssigned((currentState) => !currentState);
        } else {
          toast.error("Oops, something went wrong", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          LoaderClose();
          closeModalnew();
          setError({});
          setUsernameassin();
          setDuedate();
        }
      })
      .catch((error) => {
        const errorMessage = "Oops, something went wrong";
        toast.error(errorMessage, {
          // Corrected 'error.message'
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        LoaderClose();
        closeModalnew();
        setError({});
        setUsernameassin();
        setDuedate();
      });
  };

  //activity search
  const [activitySearch, setActivitySearch] = useState({});
  const [isDropdownActive, setIsDropdownActive] = useState({});
  const dropdownRefs = useRef([]);
  const inputRefs = useRef([]);

  useEffect(() => {
    dropdownRefs.current = dropdownRefs.current.slice(0, data.length);
    inputRefs.current = inputRefs.current.slice(0, data.length);
  }, [data]);

  const toggleDropdown = (index) => {
    setIsDropdownActive((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
    if (isDropdownActive[index]) {
      setActivitySearch({
        ...activitySearch,
        [index]: "",
      });
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      let anyDropdownActive = false;

      const newIsDropdownActive = { ...isDropdownActive };
      const newActivitySearch = { ...activitySearch };

      dropdownRefs.current.forEach((dropdown, index) => {
        if (dropdown && !dropdown.contains(event.target)) {
          if (isDropdownActive[index]) {
            newIsDropdownActive[index] = false;
            newActivitySearch[index] = "";
            anyDropdownActive = true;
          }
        }
      });

      if (anyDropdownActive) {
        setIsDropdownActive(newIsDropdownActive);
        setActivitySearch(newActivitySearch);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownActive, activitySearch]);

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState({});

  useEffect(() => {
    const searchHandler = setTimeout(() => {
      setDebouncedSearchTerm(activitySearch);
      setSearching({});
    }, 300);

    setSearching((prev) => ({
      ...prev,
      [Object.keys(activitySearch).find(
        (key) => activitySearch[key] !== prev[key]
      )]: true,
    }));

    return () => {
      clearTimeout(searchHandler);
    };
  }, [activitySearch]);

  const filteredActivities = useMemo(() => {
    const result = {};
    Object.keys(rowsState).forEach((index) => {
      const searchTerm = (activitySearch[index] || "").toLowerCase();
      if (!searchTerm) {
        result[index] = rowsState[index].activities;
      } else {
        result[index] = rowsState[index].activities.filter(
          (item) =>
            item.name.toLowerCase().includes(searchTerm) ||
            item.source.toLowerCase().includes(searchTerm) ||
            item.unit_type.toLowerCase().includes(searchTerm) ||
            item.region.toLowerCase().includes(searchTerm) ||
            item.year.toString().includes(searchTerm) ||
            (item.source_lca_activity !== "unknown" &&
              item.source_lca_activity.toLowerCase().includes(searchTerm))
        );
      }
    });
    return result;
  }, [rowsState, activitySearch]);

  const [searching, setSearching] = useState({});

  useEffect(() => {
    const searchHandler = setTimeout(() => {
      setDebouncedSearchTerm(activitySearch);
      setSearching({});
    }, 300);

    return () => {
      clearTimeout(searchHandler);
    };
  }, [activitySearch]);

  //autofill
  useEffect(() => {
    let isMounted = true;

    const fetchAndUpdateActivities = async () => {
      let initialRows = [
        //   {
        //   category: "",
        //   subCategory: "",
        //   activities: [],
        //   value1: "",
        //   value2: "",
        //   unit: ["", ""],
        //   fileRaw: "",
        //   file: null,
        //   fileName: "",
        //   modifiedTime: "",
        //   activity: "",
        //   selectedActivity: {},
        //   unitType: "",
        //   assignTo: "",
        //   uploadedBy: currentUser,
        //   noActivities: false,
        //   fetchingActivities: false,
        // }
      ];

      // Function to process rows, separated to reuse for both prevMonth and approvedRows
      const processRows = async (rows, rowsSource) => {
        setLoOpen(true);
        return await Promise.all(
          rows.map(async (row, index) => {
            console.log("rows received in processRows:", rows);

            const newSubCategory = row.subCategory || "";
            try {
              let allActivities = [];

              const response = await fetchActivities(
                index,
                newSubCategory,
                1,
                false
              );
              const { activitiesData, pages, pagesCustom } = response;

              activitiesData.sort((a, b) =>
                a.access_type === "private" && b.access_type !== "private"
                  ? -1
                  : 1
              );

              allActivities = activitiesData;

              if (pages > 1) {
                for (let page = 2; page <= pages; page++) {
                  let customFetched =
                    pagesCustom && page <= pagesCustom ? false : true;
                  const additionalActivities = await fetchActivities(
                    index,
                    newSubCategory,
                    page,
                    customFetched
                  );
                  allActivities = [
                    ...allActivities,
                    ...additionalActivities.activitiesData,
                  ];
                }
              }

              const finalActivities = allActivities.filter(
                (activity, index, self) =>
                  self.findIndex((item) => item.id === activity.id) === index
              );

              const filteredActivities = finalActivities.filter(
                (activity) => activity.id === row.emmissionfactorid
              );

              const selectedActivity =
                filteredActivities.length > 0 ? filteredActivities[0] : {};

              console.log("selectedActivity:", selectedActivity);

              return {
                ...row,
                // activity: `${row.name} - (${row.source}) - ${
                //   selectedActivity.unit_type || ""
                // }`,
                activity: `${selectedActivity.name} - (${
                  selectedActivity.source
                }) - ${selectedActivity.unit_type || ""}`,
                activities: finalActivities,
                noActivities: finalActivities.length === 0,
                fetchingActivities: false,
                selectedActivity,
                unitType: selectedActivity.unit_type || "",
                value1: rowsSource === "prevMonth" ? "" : row.value1,
                value2: rowsSource === "prevMonth" ? "" : row.value2,
                unit: rowsSource === "prevMonth" ? ["", ""] : row.unit,
                assignTo: rowsSource === "prevMonth" ? "" : row.assignTo,
                rowType: rowsSource === "prevMonth" ? "prevMonth" : "approved",
                rowSource:
                  rowsSource === "prevMonth" ? "Previous Month" : "Approved",
                fileRaw: rowsSource === "prevMonth" ? "" : row.fileRaw,
                file: rowsSource === "prevMonth" ? "" : row.file,
                fileName: rowsSource === "prevMonth" ? "" : row.fileName,
                filename: rowsSource === "prevMonth" ? "" : row.filename,
                assign_to: rowsSource === "prevMonth" ? "" : row.assign_to,
                modifiedTime: rowsSource === "prevMonth" ? "" : row.modifiedTime,
                file_modified_at: rowsSource === "prevMonth" ? "" : row.file_modified_at,

              };
            } catch (error) {
              console.error(
                "Error fetching activities for subCategory:",
                newSubCategory,
                error
              );
              return {
                ...row,
                activities: [],
                noActivities: true,
                fetchingActivities: false,
                rowType: rowsSource === "prevMonth" ? "prevMonth" : "approved",
                rowSource:
                  rowsSource === "prevMonth" ? "Previous Month" : "Approved",
              };
            } finally {
              setLoOpen(false);
            }
          })
        );
      };

      // Collect all emission IDs from approvedRows and assignedData if present
      const allEmissionIds = new Set([
        ...approvedRows.map(row => row.emmissionfactorid),
        ...assignedData.map(row => row.emmissionfactorid)
      ]);

      // Filter prevMonth to exclude rows with matching emissionfactorid
      const filteredPrevMonth = prevMonth.filter(
        row => !allEmissionIds.has(row.emmissionfactorid)
      );

      console.log("all emission ids", allEmissionIds);
      console.log("filtered prev month", filteredPrevMonth);
      
      if (prevMonth.length > 0 && data.length === 0 && monthlyEmissions === 0) {
        initialRows = await processRows(filteredPrevMonth, "prevMonth");
      }

      if (approvedRows.length > 0) {
        const approvedActivitiesRows = await processRows(
          approvedRows,
          "approved"
        );
        initialRows = [...initialRows, ...approvedActivitiesRows];
      }

      if (isMounted) {
        setRows(initialRows);
        dispatch(setRowsStateNew({ quarter, scope, rows: initialRows }));
      }
    };

    const fetchData = async () => {
      try {
        LoaderOpen();
        await fetchAndUpdateActivities();
        setTimeout(() => {
          LoaderClose();
        }, 500);
      } catch (error) {
        console.error("Error during data fetch:", error);
        LoaderClose();
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [
    currentUser,
    data.length,
    dispatch,
    prevMonth,
    approvedRows,
    quarter,
    scope,
    scopeInfo.length,
    monthlyEmissions,
  ]);

  return (
    <>
      <ToastContainer style={{ fontSize: "12px" }} />
      <div className="bg-white rounded-lg shadow border border-neutral-200 py-4 px-2 show w-full">
        <div>
          <div className="w-12 h-6 flex-col justify-center items-start inline-flex">
            <span className="">
              <div className="h-4 px-1 py-0.5 opacity-80 bg-gradient-to-r from-[#007EEF] to-[#2AE4FF] rounded-sm justify-start items-start gap-2.5 inline-flex ms-5">
                <div className="text-white text-[11px] font-bold uppercase leading-none">
                  {quarter}
                </div>
              </div>
            </span>
          </div>
        </div>
        <table
          className={`${
            data ? "mb-5" : "mb-5 mt-4"
          } w-full text-xs text-start text-[#707070] mt-2`}
        >
          <thead>
            <tr className="border-b border-[#EDEAE9] bg-[#f8f9fb] h-11">
              <th className="w-[8px] px-2 py-2 text-left"></th>
              <th className="w-[17.25%] px-2 py-2 text-left">Category</th>
              <th className="w-[17.25%] px-4 py-2 text-left">SubCategory</th>
              <th className="w-[17.25%] px-4 py-2 text-left">Activity</th>
              <th className="w-[27.21%] px-4 py-2 text-center">Quantity</th>
              {/* <th className="w-[118px] px-4 py-2 text-left">Upload</th> */}
              <th className="w-[8.26%] px-4 py-2 text-left">Assignee</th>
              <th className="w-[8.70%] px-4 py-2 text-left">Actions</th>
            </tr>
          </thead>
          <tbody>
            <ShowEmission data={data} scope={scope} scopeInfo={scopeInfo} />
            <AssignedTaskRows
              data={assignedData}
              scope={scope}
              scopeInfo={scopeInfo}
            />
            {rowsState?.map((row, index) => (
              <tr key={index} className="border-b border-[#EDEAE9] h-11">
                <td className="w-[8px] py-2 text-left">
                  <CircleRounded
                    style={{
                      color: row.rowSource === "Approved" ? "orange" : "white",
                      fontSize: "8px",
                    }}
                  />
                </td>
                <td className="w-[17.25%]">
                  <div className="relative">
                    <select
                      value={row.category}
                      onChange={(event) =>
                        handleChange(event, index, "category")
                      }
                      className="cursor-pointer appearance-none bg-white py-2 rounded leading-tight outline-none m-[3px] w-full truncate"
                      disabled={row.rowSource === "Approved"}
                    >
                      <option value="">Select Category</option>
                      {scopeInfo[0]?.Category?.map((item) => (
                        <option key={item.name}>{item.name}</option>
                      ))}
                    </select>
                    <div className="absolute inset-y-0 right-0 flex items-center pointer-events-none">
                      <KeyboardArrowDownOutlined
                        className="text-neutral-500"
                        style={{ fontSize: "20px" }}
                      />
                    </div>
                    {selectedCategory && (
                      <div
                        className="absolute left-0 right-0 bottom-0 h-px mb-1 bg-gray-500"
                        // You can adjust the styles and colors of the underline as needed
                      />
                    )}
                  </div>
                </td>
                <td className="w-[17.25%]">
                  <div className="relative">
                    <select
                      value={row.subCategory}
                      onChange={(event) =>
                        handleChange(event, index, "subCategory")
                      }
                      className="cursor-pointer appearance-none bg-white px-4 py-2 rounded leading-tight outline-none m-[3px] w-full truncate"
                      disabled={row.rowSource === "Approved"}
                    >
                      <option value="">Select SubCategory</option>
                      {scopeInfo[0]?.Category?.map((item) => {
                        if (item.name === row.category && item.SubCategory) {
                          return item.SubCategory.map((subItem) => (
                            <option key={subItem}>{subItem}</option>
                          ));
                        }
                        return null;
                      })}
                    </select>
                    <div className="absolute inset-y-0 -right-2 flex items-center pointer-events-none">
                      <KeyboardArrowDownOutlined
                        className="text-neutral-500"
                        style={{ fontSize: "20px" }}
                      />
                    </div>
                    {selectedSubCategory && (
                      <div className="absolute left-0 right-0 bottom-0 h-px mb-1 bg-gray-500" />
                    )}
                  </div>
                </td>
                <td className="w-[17.25%]">
                  <div key={index} className="relative">
                    <input
                      type="text"
                      placeholder={
                        row.fetchingActivities
                          ? "Fetching activities..."
                          : row.noActivities
                          ? "No relevant activities found"
                          : row.activity
                          ? row.activity
                          : "Select Activity"
                      }
                      value={activitySearch[index] || ""}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setActivitySearch((prev) => ({
                          ...prev,
                          [index]: newValue,
                        }));
                        setIsDropdownActive((prev) => ({
                          ...prev,
                          [index]: true,
                        }));
                      }}
                      onFocus={() => toggleDropdown(index)}
                      className="w-full px-4 py-2 mb-2 ms-2 rounded focus:outline-none"
                      disabled={row.rowSource === "Approved"}
                    />

                    {isDropdownActive[index] &&
                      row.rowSource !== "Approved" && (
                        <select
                          ref={(el) => (dropdownRefs.current[index] = el)}
                          size="9"
                          value={row.activity}
                          onChange={(event) => {
                            handleChange(event, index, "activity");
                            toggleDropdown(index);
                            setActivitySearch((prev) => ({
                              ...prev,
                              [index]: "",
                            }));
                          }}
                          className="absolute left-1 max-w-[850px] cursor-pointer bg-white py-2 border-2 rounded-lg z-10 pb-2"
                          disabled={row.rowSource === "Approved"}
                        >
                          <option value="" className="px-1">
                            {searching[index]
                              ? "Searching..."
                              : row.fetchingActivities
                              ? "Fetching activities..."
                              : row.noActivities
                              ? "No relevant activities found"
                              : "Select Activity"}
                          </option>
                          {filteredActivities[index]?.map((item) => (
                            <option
                              key={item.id}
                              data-unit-type={item.unit_type}
                              data-id={item.id}
                              value={`${item.name} - (${item.source}) - ${item.unit_type}`}
                              className="px-2"
                            >
                              {item.name} - ({item.source}) - {item.unit_type} -{" "}
                              {item.region} - {item.year}
                              {item.source_lca_activity !== "unknown" &&
                                ` - ${item.source_lca_activity}`}
                            </option>
                          ))}
                        </select>
                      )}
                    <div
                      className="absolute inset-y-0 -right-2 flex items-center cursor-pointer"
                      onClick={() => toggleDropdown(index)}
                    >
                      {isDropdownActive[index] ? (
                        <KeyboardArrowUpOutlined
                          className="text-neutral-500"
                          style={{ fontSize: "20px" }}
                        />
                      ) : (
                        <KeyboardArrowDownOutlined
                          className="text-neutral-500"
                          style={{ fontSize: "20px" }}
                        />
                      )}
                    </div>
                  </div>
                </td>

                {!row.unitType.includes("Over") ? (
                  <td className="relative w-[27.21%]">
                    <div className="flex w-full">
                      <div className="flex-grow">
                        <input
                          type="number"
                          className="w-full p-2 rounded-sm mt-1 ms-2 alignment"
                          value={row.value1}
                          onChange={(event) =>
                            handleChange(event, index, "value1")
                          }
                          disabled={row.rowSource === "Approved"}
                        />
                      </div>
                      <div className="absolute right-0 top-0.5">
                        <select
                          value={row.unit[0]}
                          onChange={(event) =>
                            handleChange(event, index, "unit1")
                          }
                          className={`cursor-pointer appearance-none px-2 py-1 rounded-md leading-tight outline-none ms-1 mt-1.5 font-bold text-xs ${
                            row.unit[0]
                              ? "text-sky-600 bg-white drop-shadow-md"
                              : "bg-sky-600 text-white"
                          }`}
                          style={{ width: "66px" }}
                          disabled={row.rowSource === "Approved"}
                        >
                          <option className="text-xs">Unit</option>
                          {row.activity &&
                            row.unitType &&
                            units
                              .filter((unit) => unit.unit_type === row.unitType)
                              .reduce((combinedUnits, unit) => {
                                return combinedUnits.concat(
                                  Object.values(unit.units)
                                );
                              }, [])
                              .flat()
                              .map((unitName) => (
                                <option key={unitName} className="text-xs">
                                  {unitName}
                                </option>
                              ))}
                        </select>
                        <span className="absolute right-2 top-4 transform -translate-y-1/2 pointer-events-none">
                          <ArrowDropDown
                            className={`text-xs ${
                              row.unit[0] ? "text-sky-600" : "text-white "
                            }`}
                          />
                        </span>
                      </div>
                    </div>
                  </td>
                ) : (
                  <td className="w-[27.21%]">
                    <div className="flex items-center">
                      <div className="relative w-1/2">
                        <input
                          type="number"
                          value={row.value1}
                          onChange={(event) =>
                            handleChange(event, index, "value1")
                          }
                          className="w-full p-2 rounded-sm mt-1 ms-2 alignment"
                          disabled={row.rowSource === "Approved"}
                        />
                        <div className="absolute right-0 top-0.5">
                          <select
                            value={row.unit[0]}
                            onChange={(event) =>
                              handleChange(event, index, "unit1")
                            }
                            className={`cursor-pointer appearance-none px-2 pe-5 py-1 rounded-md leading-tight outline-none ms-1 mt-1.5 font-bold text-xs ${
                              row.unit[0]
                                ? "text-sky-600 bg-white drop-shadow-md"
                                : "bg-sky-600 text-white"
                            } w-[66px]`}
                            style={{ width: "66px" }}
                            disabled={row.rowSource === "Approved"}
                          >
                            <option className="text-xs truncate">Unit</option>
                            {row.activity &&
                              row.unitType &&
                              units
                                .filter(
                                  (unit) => unit.unit_type === row.unitType
                                )
                                .map((unit) => {
                                  const unitValues = Object.values(unit.units);
                                  if (unitValues.length >= 2) {
                                    const firstArray = unitValues[0];
                                    return firstArray;
                                  }
                                  return [];
                                })
                                .flat()
                                .map((unitName) => (
                                  <option
                                    key={unitName}
                                    className="text-xs truncate"
                                  >
                                    {unitName}
                                  </option>
                                ))}
                          </select>

                          <span className="absolute right-0 top-[1.15rem] transform -translate-y-1/2 pointer-events-none">
                            <ArrowDropDown
                              className={`text-xs ${
                                row.unit[0] ? "text-sky-600" : "text-white "
                              }`}
                            />
                          </span>
                        </div>
                      </div>

                      <div className="relative w-1/2 ">
                        <input
                          type="number"
                          className="w-full p-2 rounded-sm mt-1 ms-2 alignment"
                          value={row.value2}
                          onChange={(event) =>
                            handleChange(event, index, "value2")
                          }
                          disabled={row.rowSource === "Approved"}
                        />
                        <div className="absolute right-0 top-0.5">
                          <select
                            value={row.unit[1]}
                            onChange={(event) =>
                              handleChange(event, index, "unit2")
                            }
                            className={`cursor-pointer appearance-none  px-2 py-1 rounded-md leading-tight outline-none ms-1 mt-1.5 font-bold text-xs ${
                              row.unit[1]
                                ? "text-sky-600 bg-white drop-shadow-md"
                                : "bg-sky-600 text-white"
                            }`}
                            style={{ width: "66px" }}
                            disabled={row.rowSource === "Approved"}
                          >
                            <option className="text-xs">Unit</option>
                            {row.activity &&
                              row.unitType &&
                              units
                                .filter(
                                  (unit) => unit.unit_type === row.unitType
                                )
                                .map((unit) => {
                                  const unitValues = Object.values(unit.units);
                                  if (unitValues.length >= 2) {
                                    return unitValues[1]; // Get the second array within units
                                  }
                                  return [];
                                })
                                .flat()
                                .map((unitName) => (
                                  <option key={unitName} className="text-xs">
                                    {unitName}
                                  </option>
                                ))}
                          </select>
                          <span className="absolute right-2 top-4 transform -translate-y-1/2 pointer-events-none">
                            <ArrowDropDown
                              className={`text-xs ${
                                row.unit[1] ? "text-sky-600" : "text-white "
                              }`}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>
                )}
                <td className="w-[118px]">
                  <div className="flex ml-2">
                    <div
                      className={`w-[112px] h-[30px] pr-2.5 py-1 ${
                        row.assignTo == ""
                          ? "bg-sky-600 text-white"
                          : "text-[#0056a4] bg-white drop-shadow-md"
                      } rounded-md border shadow-lg flex-col justify-center items-center inline-flex overflow-x-hidden`}
                      onClick={() =>
                        row.rowSource !== "Approved" && openModal(row, index)
                      }
                      disabled={row.rowSource === "Approved"}
                    >
                      <div
                        className={`justify-start items-center gap-2 inline-flex`}
                      >
                        {row.assignTo == "" ? (
                          <div className="relative left-0 text-[13px] font-medium leading-snug tracking-wide cursor-pointer">
                            {" "}
                            Assign To
                          </div>
                        ) : (
                          <div className="relative w-[85px] left-1 text-[13px] font-medium leading-snug tracking-wide cursor-pointer">
                            {row.assignTo}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="w-[8.70%] relative">
                  <div className="flex items-center ml-3">
                    <div className="mr-1.5">
                      {row.rowSource !== "Approved" ? (
                        <DeleteOutline
                          className="text-red-600 cursor-pointer"
                          onClick={() => handleDeleteRow(index)}
                        />
                      ) : (
                        <MoreVert
                          className="cursor-not-allowed"
                          // onClick={() => toggleRowDropdown(index)}
                        />
                      )}
                    </div>
                    <div className="bg-red relative">
                      <label
                        htmlFor={
                          row.file
                            ? undefined
                            : `fileInput-${index}-${scope}-${row.id}`
                        }
                        className={`absolute left-2 -top-5 cursor-pointer scope-${scope}`}
                      >
                        <div className="flex items-center justify-center h-10">
                          {showCheckmark ? (
                            <CheckOutlined className="w-6 h-6 text-green-500" />
                          ) : row.file ? (
                            <div
                              className="flex items-center"
                              data-tip
                              data-for={`fileTooltip-${index}`}
                              onClick={(event) => {
                                if (row.file) {
                                  event.preventDefault();
                                  handleGreenIconClick(index, row.rowSource);
                                }
                              }}
                            >
                              <div>
                                <a
                                  data-tooltip-id="filename-tooltip"
                                  data-tooltip-html={row.fileName}
                                  data-tooltip-variant="dark"
                                >
                                  <FileIconExcel
                                    className="w-6 h-6 mr-1 text-gray-400"
                                    style={{ color: "green" }}
                                  />
                                </a>
                                <Tooltip
                                  id="filename-tooltip"
                                  className="absolute rounded py-1 px-2 leading-5"
                                />
                              </div>
                            </div>
                          ) : (
                            <div
                              className={
                                row.rowSource === "Approved"
                                  ? "cursor-not-allowed"
                                  : "cursor-pointer"
                              }
                            >
                              <FileUploadOutlined
                                className={`w-6 h-6 text-sky-600 hover:text-gray-700 ${
                                  row.rowSource === "Approved"
                                    ? "cursor-not-allowed"
                                    : "cursor-pointer"
                                }`}
                              />
                            </div>
                          )}
                        </div>
                      </label>
                      <input
                        id={`fileInput-${index}-${scope}-${row.id}`}
                        type="file"
                        className="hidden scope-${scope}"
                        onChange={(event) => handleChange(event, index, "file")}
                        disabled={row.file || row.rowSource === "Approved"}
                      />

                      <PdfPreviewModal
                        isOpen={showPreviewModal && previewRowIndex === index}
                        onClose={closeModal}
                        file={row.file}
                        fileName={row.fileName}
                        modifiedTime={row.modifiedTime}
                        row={row}
                        scope={scope}
                        uploadedBy={row.uploadedBy}
                        activeRowIndex={index}
                      />
                      {iframePreview && (
                        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-20 rounded-md">
                          <div
                            className="relative bg-white rounded-lg shadow-lg flex"
                            style={{ width: "999px", height: "659px" }}
                          >
                            <div className="flex flex-col w-[711px] h-[650px]">
                              <iframe
                                title="PDF Viewer"
                                src={row.file}
                                width="100%"
                                height="100%"
                                style={{
                                  border: "8px solid #F5F5F5",
                                  backgroundColor: "white",
                                }}
                              />
                            </div>

                            <div className="flex flex-col w-[288px] px-4 py-4 bg-white gap-5 mt-[5rem]">
                              <h3 className="text-lg font-semibold mb-2">
                                File Information
                              </h3>
                              <div className="text-sm">
                                <p className="font-semibold uppercase">File Name:</p>
                                <p>{row.fileName || "N/A"}</p>
                                <br/>
                                <p className="font-semibold mt-2 uppercase">File Type:</p>
                                <p>N/A</p>
                                <br/>
                                <p className="font-semibold mt-2 uppercase">File Size:</p>
                                <p>
                                  N/A
                                </p>
                                <br/>
                                <p className="font-semibold mt-2 uppercase">
                                  Last Modified:
                                </p>
                                <p>{row?.modifiedTime || "N/A"}</p>
                                <br/>
                                <p className="font-semibold mt-2 uppercase">
                                  Uploaded By:
                                </p>
                                <p>{row.assignTo ? row.assignTo : "No Username found"}</p>
                                <br/>
                              </div>
                            </div>

                            <button
                              onClick={() => setIframePreview(false)}
                              style={{
                                position: "absolute",
                                top: "10px",
                                right: "20px",
                                background: "transparent",
                                border: "none",
                                color: "black",
                                fontSize: "24px",
                                cursor: "pointer",
                              }}
                              aria-label="Close PDF Viewer"
                            >
                              &times;
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex justify-between items-center">
          <button
            className="font-bold py-6 px-4 rounded text-xs opacity-70 text-sky-600"
            onClick={handleAddRow}
          >
            <AddOutlined style={{ fontSize: "20px", marginBottom: "3px" }} />{" "}
            Add new
          </button>
          <div className="me-4">
            {errorFieldNames.length > 0 && (
              <p className="text-xs text-red-600">
                <ErrorOutlined style={{ fontSize: "16px" }} />
                The following fields are required:{" "}
                <span className="">{` ${errorFieldNames.join(", ")}`}</span>
              </p>
            )}
          </div>
        </div>
      </div>
      <>
        {isModalOpen && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="modal-content relative  bg-white rounded-md px-5 pt-5 overflow-auto w-[400px] h-[580px]">
              <div>
                <div className="mb-5">
                  <h5 className="text-left text-sm">Assign user</h5>
                  <p className="text-left text-sm text-gray-500">
                    Assign a user and select a due date.
                  </p>
                </div>
              </div>
              <div className="px-4 pt-4">
                <div className="flex justify-between">
                  <div className="mb-5">
                    <div className="w-[13rem]">
                      <h5 className="text-left text-sm">Location</h5>
                      <p className="text-left text-sm text-gray-500">
                        {location}
                      </p>
                    </div>
                  </div>
                  <div className="mb-5">
                    <div className="w-[5rem]">
                      <h5 className="text-left text-sm">Year</h5>
                      <p className="text-left text-sm text-gray-500">
                        {" "}
                        {fetchedYear}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex justify-between">
                  <div className="mb-5">
                    <div className="w-[13rem]">
                      <h5 className="text-left text-sm">Month</h5>
                      <p className="text-left text-sm text-gray-500">
                        {quarter}
                      </p>
                    </div>
                  </div>
                  <div className="mb-5">
                    <div className="w-[5rem]">
                      <h5 className="text-left text-sm">Scope</h5>
                      <p className="text-left text-sm text-gray-500">
                        Scope {scope}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mb-5">
                  <h5 className="text-left text-sm">Subcategory</h5>
                  <p className="text-left text-sm text-gray-500 w-full">
                    {assigndatasubCategory}
                  </p>

                  {error.assigndatasubCategory && (
                    <p className="text-red-500 ml-1 text-left">
                      {error.assigndatasubCategory}
                    </p>
                  )}
                </div>
                <div className="mb-5">
                  <h5 className="text-left text-sm">Activity</h5>
                  <p className="text-left text-sm text-gray-500 w-full">
                    {assigndataactivity
                      ? assigndataactivity
                      : "No activity selected"}
                  </p>

                  {/* {error.assigndataactivity && (
                    <p className="text-red-500 ml-1 text-left">
                      {error.assigndataactivity}
                    </p>
                  )} */}
                </div>
                <div className="mb-5">
                  <div className="mr-2">
                    <label
                      htmlFor="sdate"
                      className="block text-neutral-800 text-[13px] font-normal text-left"
                    >
                      Assign User
                    </label>
                    <div className="mt-2">
                      <select
                        className="block w-full rounded-md border-0 py-2 pl-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        name="user"
                        value={usernameasssin}
                        onChange={handleUsername}
                      >
                        <option>--- Select user ---</option>
                        {clintlist &&
                          clintlist.map((item, index) => (
                            <option
                              key={index}
                              value={item.user} // Assuming each item has an id attribute
                              data-name={item.user_name} // Storing the user_name for potential use
                            >
                              {item.user_name}
                            </option>
                          ))}
                      </select>
                      {error.usernameasssin && (
                        <p className="text-red-500 ml-1 text-left">
                          {error.usernameasssin}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mb-5">
                  <div className="mr-2">
                    <label
                      htmlFor="sdate"
                      className="block text-neutral-800 text-[13px] font-normal text-left"
                    >
                      Due date
                    </label>
                    <div className="mt-2">
                      <input
                        id="edate"
                        name="enddate"
                        type="date"
                        autoComplete="edate"
                        value={duedate}
                        min={getTodayDate()}
                        onChange={hanleDuedate}
                        required
                        placeholder="End date"
                        className="block w-full px-1 rounded-md border-0 py-1.5 pl-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                      {error.duedate && (
                        <p className="text-red-500 ml-1 text-left">
                          {error.duedate}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-2">
                  <div>
                    <button
                      className="bg-white border border-gray-300 text-black py-1 rounded-md shadow-sm w-full text-center text-md"
                      onClick={closeModalnew}
                    >
                      Cancel
                    </button>
                  </div>
                  <div>
                    <button
                      className="bg-[#007EEF] border border-[#007EEF] text-white py-1 rounded-md shadow-sm w-full text-center text-md"
                      onClick={handleSubmit}
                    >
                      Assign
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        ;
      </>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loopen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default CollectEmission;